import {
  Avatar,
  Box,
  Button,
  Dialog,
  Input,
  Island,
  Label,
  Textarea,
  TextBody,
  TextSmall,
} from '@teamleader/ui';
import React, { useRef, useState } from 'react';
import { useUploadFile } from 'react-firebase-hooks/storage';
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import {
  firebaseDb,
  storageReferralWheelImagesRef,
} from '../services/firestore';

const ALLOWED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

const ItemEditorDialog = ({ item, onClose, setItemInEditMode }) => {
  const [uploadFile, uploading, snapshot, error] = useUploadFile();
  const [imageSelectError, setImageSelectError] = useState(null);
  const imageInputRef = useRef();
  const { id, description, imageUrl, title } = item;

  const handleTitleChange = (event) => {
    setItemInEditMode({ ...item, title: event.target.value });
  };

  const handleDescriptionChange = (event) => {
    setItemInEditMode({ ...item, description: event.target.value });
  };

  const handleImageChange = () => {
    imageInputRef.current?.click();
  };

  const handleImageInputChange = async () => {
    const image = imageInputRef.current.files?.[0];

    setImageSelectError(null);

    if (!image) {
      return;
    }

    if (!ALLOWED_IMAGE_TYPES.includes(image.type)) {
      setImageSelectError('Please select a JPG, JPEG or PNG image type');
      return;
    }

    const imageStorageRef = ref(storageReferralWheelImagesRef, image.name);
    const result = await uploadFile(imageStorageRef, image, {
      contentType: image.type,
    });
    const imageDownloadUrl = await getDownloadURL(imageStorageRef);
    if (result) {
      setItemInEditMode({
        ...item,
        imageUrl: imageDownloadUrl,
      });
    }
  };

  const handleItemSaveClick = async () => {
    if (id) {
      const docRef = doc(firebaseDb, 'referral-wheel', id);
      await updateDoc(docRef, {
        description,
        imageUrl,
        title,
      });
    } else {
      const collectionRef = collection(firebaseDb, 'referral-wheel');
      await addDoc(collectionRef, {
        description,
        ...(imageUrl && { imageUrl }),
        title,
      });
    }

    setItemInEditMode(null);
  };

  return (
    <Dialog
      active
      onCloseClick={onClose}
      onEscKeyDown={onClose}
      primaryAction={{
        label: 'Save & close',
        onClick: handleItemSaveClick,
      }}
      secondaryAction={{
        label: 'Cancel',
        onClick: onClose,
      }}
      size="large"
      title={id ? `Edit item: ${title}` : `Add item`}
    >
      <Box padding={5}>
        <Box display="flex">
          <Box>
            <Avatar imageUrl={imageUrl} size="hero" shape="rounded" />
            <input
              type="file"
              accept={ALLOWED_IMAGE_TYPES.join(',')}
              ref={imageInputRef}
              style={{ display: 'none' }}
              onChange={handleImageInputChange}
            />
            <Box display="flex" flexDirection="column">
              <Button
                marginTop={3}
                onClick={handleImageChange}
                processing={uploading}
                size="small"
              >
                {imageUrl ? 'Change image' : 'Add image'}
              </Button>
              <TextSmall marginTop={2} textAlign="center">
                Only <strong>jpg</strong>, <strong>jpeg</strong> or{' '}
                <strong>png</strong>
              </TextSmall>
            </Box>
          </Box>
          <Box flex={1} marginLeft={5}>
            <Label required>
              Title
              <Input onChange={handleTitleChange} value={title} />
            </Label>
            <Label required>
              Description
              <Textarea
                onChange={handleDescriptionChange}
                value={description}
              />
            </Label>
          </Box>
        </Box>
        {imageSelectError && (
          <Island color="ruby" size="small">
            <TextBody>{imageSelectError}</TextBody>
          </Island>
        )}
        {error && (
          <Island color="ruby" size="small">
            <TextBody>{error}</TextBody>
          </Island>
        )}
      </Box>
    </Dialog>
  );
};

export default ItemEditorDialog;
