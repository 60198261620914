import React, { useState } from 'react';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import styles from '../App.css';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Container,
  Heading2,
  Island,
  Link,
  Marker,
  MarketingHeading1,
  TextBody,
  TextDisplay,
} from '@teamleader/ui';

import { firebaseDb } from '../services/firestore';
import ItemEditorDialog from './ItemEditorDialog';

const MAX_ALLOWED_ITEMS = 10;

const Admin = () => {
  const [items] = useCollection(collection(firebaseDb, 'referral-wheel'));
  const [itemInEditMode, setItemInEditMode] = useState(null);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [
    isRemoveItemConfirmationAlertActive,
    setIsRemoveItemConfirmationAlertActive,
  ] = useState(false);

  const handleItemAddClick = () => {
    setItemInEditMode({});
  };

  const handleItemEditClick = (item) => {
    setItemInEditMode(item);
  };

  const handleItemEditDialogClose = () => {
    setItemInEditMode(null);
  };

  const handleItemRemoveClick = ({ id, title }) => {
    setItemToRemove({ id, title });
    setIsRemoveItemConfirmationAlertActive(true);
  };

  const handleItemRemoveConfirmClick = async () => {
    await deleteDoc(doc(firebaseDb, 'referral-wheel', itemToRemove?.id));
    setIsRemoveItemConfirmationAlertActive(false);
    setItemToRemove(null);
  };

  const handleCloseItemRemoveConfirmationAlert = () => {
    setIsRemoveItemConfirmationAlertActive(false);
    setItemToRemove(null);
  };

  return (
    <Container fixed className={styles['container']} paddingVertical={8}>
      <MarketingHeading1 className={styles['title']} marginBottom={6}>
        Teamleaders HR Games <Marker>Admin</Marker>
      </MarketingHeading1>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        marginBottom={6}
      >
        <TextDisplay marginRight={4}>
          Items <strong>{`${items?.docs.length}/${MAX_ALLOWED_ITEMS}`}</strong>
        </TextDisplay>
        <Button
          disabled={items?.docs.length === MAX_ALLOWED_ITEMS}
          level="primary"
          onClick={handleItemAddClick}
        >
          Add item
        </Button>
      </Box>
      {items?.docs.map((doc) => {
        const { description, imageUrl, title } = doc.data();
        return (
          <Island display="flex" marginBottom={5} key={doc.id}>
            <Avatar imageUrl={imageUrl} size="large" shape="rounded" />
            <Box marginLeft={4}>
              <Heading2>{title}</Heading2>
              <TextBody marginBottom={2}>{description}</TextBody>
              <TextBody>
                <Link
                  inherit={false}
                  onClick={() =>
                    handleItemEditClick({
                      id: doc.id,
                      description,
                      imageUrl,
                      title,
                    })
                  }
                >
                  Edit
                </Link>
                {' | '}
                <Link
                  inherit={false}
                  onClick={() =>
                    handleItemRemoveClick({
                      id: doc.id,
                      title,
                    })
                  }
                >
                  Remove
                </Link>
              </TextBody>
            </Box>
          </Island>
        );
      })}
      {itemInEditMode && (
        <ItemEditorDialog
          item={itemInEditMode}
          onClose={handleItemEditDialogClose}
          setItemInEditMode={setItemInEditMode}
        />
      )}
      <Alert
        active={isRemoveItemConfirmationAlertActive}
        body={itemToRemove?.title}
        onEscKeyDown={handleCloseItemRemoveConfirmationAlert}
        onOverlayClick={handleCloseItemRemoveConfirmationAlert}
        type="destructive"
        title="Are you sure you want to remove this item?"
        primaryAction={{
          label: 'Confirm',
          onClick: handleItemRemoveConfirmClick,
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: handleCloseItemRemoveConfirmationAlert,
        }}
      />
    </Container>
  );
};

export default Admin;
