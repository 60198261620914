// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getStorage, ref } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDLfY0eQwndT7kSy8i8GN6thaSKJswb1WE',
  authDomain: 'hr-games-c11ab.firebaseapp.com',
  projectId: 'hr-games-c11ab',
  storageBucket: 'hr-games-c11ab.appspot.com',
  messagingSenderId: '954838226351',
  appId: '1:954838226351:web:9e2de91f90256a0997cb5a',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDb = getFirestore(firebaseApp);

// Get a reference to the storage service
export const storage = getStorage();

// Points to the root storage reference
export const storageRef = ref(storage);
// Points to the storage referralWheel reference
export const storageReferralWheelRef = ref(storageRef, 'referral-wheel');
// Points to the storage referralWheel images reference
export const storageReferralWheelImagesRef = ref(
  storageReferralWheelRef,
  'images'
);
