import {
  Box,
  Button,
  Container,
  DialogBase,
  Flex,
  IconButton,
  Marker,
  MarketingHeading1,
  TextDisplay,
} from '@teamleader/ui';
import { IconCloseMediumOutline } from '@teamleader/ui-icons';
import React, { useState } from 'react';
import { getFirestore, collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebaseApp } from '../services/firestore';

import Wheel from './Wheel';

import styles from '../App.css';
import Confetti from 'react-confetti';
import tlfLogo from '../tlf-logo.svg';

const SHOW_DIALOG_DELAY = 4000;

const ReferralWheel = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [value] = useCollection(
    collection(getFirestore(firebaseApp), 'referral-wheel')
  );
  const handleStartSpinningButtonClick = () => {
    if (selectedItem === null) {
      const item = Math.floor(Math.random() * value?.docs?.length);
      setSelectedItem(item);
      setTimeout(() => setIsDialogOpen(true), SHOW_DIALOG_DELAY);
    } else {
      setSelectedItem(null);
    }
  };

  const handleDialogCloseClick = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className={styles['app']}>
      <img
        className={styles['tlf-logo']}
        src={tlfLogo}
        height="35"
        width="53"
      />
      <Container fixed className={styles['container']}>
        <Flex direction="row" gap={8}>
          <Flex direction="column" justifyContent="center">
            <MarketingHeading1 className={styles['title']} marginBottom={6}>
              Teamleader's Employee <Marker>Referral Wheel</Marker>
            </MarketingHeading1>
            <Button
              fullWidth
              level="primary"
              size="large"
              onClick={handleStartSpinningButtonClick}
            >
              Start spinning!
            </Button>
          </Flex>
          <Wheel
            marginLeft={7}
            items={value?.docs}
            selectedItem={selectedItem}
          />
        </Flex>
      </Container>
      {isDialogOpen && (
        <>
          <Confetti />
          <DialogBase
            active
            className={styles['dialog']}
            onEscKeyDown={handleDialogCloseClick}
            onOverlayClick={handleDialogCloseClick}
          >
            <Box display="flex">
              <Box
                className={styles['dialog-left']}
                backgroundColor="violet"
                backgroundTint="dark"
                alignItems="center"
                display="flex"
                style={{
                  backgroundImage: `url(${
                    value?.docs[selectedItem].data().imageUrl
                  })`,
                }}
                flex="540px 0 0"
                padding={5}
                textAlign="center"
              >
                <MarketingHeading1 className={styles['dialog-left-title']}>
                  {value?.docs[selectedItem].data().title}
                </MarketingHeading1>
              </Box>
              <Box
                className={styles['dialog-right']}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                padding={5}
              >
                <MarketingHeading1
                  className={styles['dialog-right-title']}
                  marginBottom={5}
                >
                  Congratulations!
                </MarketingHeading1>
                <TextDisplay marginBottom={5}>
                  {value?.docs[selectedItem].data().description}
                </TextDisplay>
                <Button
                  fullWidth
                  level="primary"
                  size="large"
                  onClick={handleDialogCloseClick}
                >
                  Try again
                </Button>
              </Box>
            </Box>
            <IconButton
              className={styles['dialog-close-button']}
              icon={<IconCloseMediumOutline />}
              onClick={handleDialogCloseClick}
            />
          </DialogBase>
        </>
      )}
    </div>
  );
};

export default ReferralWheel;
