import React from 'react';
import cx from 'classnames';
import styles from './wheel.css';
import { Box } from '@teamleader/ui';
import latch from '../latch.svg';

const Wheel = ({ items, selectedItem, ...others }) => {
  const wheelVars = {
    '--nb-item': items?.length,
    '--selected-item': selectedItem,
  };

  return (
    <Box
      className={cx(styles['wheel-container'], {
        [styles['spinning']]: !!selectedItem,
      })}
      {...others}
    >
      <div className={styles['wheel']} style={wheelVars}>
        {items?.map((item, index) => (
          <div
            className={styles['wheel-item']}
            key={index}
            style={{ '--item-nb': index }}
          >
            {item.data().title}
          </div>
        ))}
      </div>
      <img className={styles['wheel-latch']} src={latch} />
    </Box>
  );
};

export default Wheel;
